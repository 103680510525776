import { ref, watch, computed } from '@vue/composition-api';
import axios from '@axios';
import _ from 'lodash';

import useAuth from '@/auth/useAuth';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n } = useI18nUtils();
function i18nT(str){
    return i18n(str)
  };
export default function useTaskList(isTemplate) {
  const refTaskListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'checkbox', label: '', thClass: 'tbl-chk', tdClass: 'tbl-chk' },
    { key: 'actions', label: '', thClass: 'tbl-chk', tdClass: 'tbl-chk' },
    { key: 'name', label: i18nT(`Name`), sortable: true, thClass: 'pl-1', tdClass: 'pl-1' },
    { key: 'type', label: i18nT(`Type`), sortable: true },
    { key: 'assigned_to', label: i18nT(`Assigned to`), sortable: true, thClass: "sk-header-content" },
    { key: 'created', label: i18nT(`Created`), sortable: true },
    { key: 'status', label: i18nT(`Status`), sortable: true },
    { key: 'due', label: i18nT(`Due`), sortable: true },
    {
      key: 'action',
      label: i18nT(`Actions`),
      thClass: 'tbl-actions',
      tdClass: 'sticky-column',
      stickyColumn: true
    },
  ];
  const perPage = ref(10);
  const totalTasks = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);
  const statusFilter = ref(null);
  const taskType = ref({ value: -1, text: i18nT('All types') });
  const taskGroup = ref({ value: -1, text: i18nT('All groups') });
  const assignedTo = ref({ value: '', text: i18nT('All') });
  const status = ref({ value: '-1', text: i18nT('All') });
  const software = ref({ value: useAuth.getCurrentSoftwareId()+'', text: useAuth.getCurrentSoftName() });
  const tasks = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = refTaskListTable.value
      ? refTaskListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTasks.value,
    };
  });

  const refetchData = () => {
    refTaskListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      statusFilter,
      taskType,
      taskGroup,
      assignedTo,
      status,
      software,
      isSortDirDesc,
      sortBy,
    ],
    () => {
      refetchData();
    }
  );

  const fetchTasks = (ctx, callback) => {
    const params = {
      page: currentPage.value,
      show_per_page: perPage.value,
      sort_by: sortBy.value,
      sort_order: isSortDirDesc.value ? 'DESC' : 'ASC',
      search: searchQuery.value
    };

    if (isTemplate) {
      params.templates = 1;
    }

    if (taskType.value.value != -1) {
      params['filters[type]'] = taskType.value.value;
    }
    if (taskGroup.value.value != -1) {
      params['filters[group]'] = taskGroup.value.value;
    }
    if (assignedTo.value.value != '') {
      params['filters[assigned_to]'] = assignedTo.value.value;
    }
    if (status.value.value != -1) {
      params['filters[status]'] = status.value.value;
    }
    if (software.value.value != -1) {
      params['filters[software]'] = software.value.value;
    }

    axios
      .get(`tasks`, {
        params,
      })
      .then(({ data }) => {
        tasks.value = data.data.tasks;
        callback(tasks.value);
        totalTasks.value = data.data.pagination.records_count;
      });
  };

  return {
    fetchTasks,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTaskListTable,
    statusFilter,
    totalTasks,
    taskType,
    taskGroup,
    assignedTo,
    status,
    software,
    tasks,
    refetchData,
  };
}
